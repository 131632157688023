<template>
  <v-container fluid>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-cart-outline
      </v-icon> My Cart
    </h2>
    <v-card
      color="#CFD8DC"
      class="pa-6"
    >
      <v-card
        min-height="300"
        class=" ma-3 d-flex justify-center align-center"
        shaped
      >
        <div
          v-if="checkCartExist"
          class="card-container"
        >
          <br>
          <v-card
            v-for="(item, index) in productRenewalCart"
            :key="index"
            elevation="0"
            outlined
            shaped
            class="w-100"
          >
            <v-card-title class="d-flex justify-space-between">
              <v-icon class="icon-style pa-4">
                mdi-monitor-dashboard
              </v-icon>
              <v-card-subtitle>
                <v-chip
                  outlined
                  label
                >
                  <h2
                    class="font-style"
                    style="color: #37474F;"
                  >
                    Dimensions : <font v-if="item.width !== null && item.height !== null">
                      {{ item.width }} x {{ item.height }}
                    </font>
                    <font v-else>
                      Not Available
                    </font>
                  </h2>
                </v-chip>
              </v-card-subtitle>
            </v-card-title>
            <v-list-item>
              <v-row>
                <v-col>
                  <v-card-title class="font-style">
                    <span class="head-style"> Device Id : &nbsp;</span><span class="value-style">{{ item.external_id
                    }}</span>
                  </v-card-title>
                  <div class="pl-4 pt-1 font-style">
                    <span class="head-style"> Current Plan :</span><span class="value-style"> {{ item.licence.type |
                      capitalizeFirstLetter }}</span>
                  </div>
                  <div class="font-style pl-4 pt-2">
                    <span class="head-style"> Device Number : </span><span class="value-style">{{ item.board_number
                    }}</span>
                  </div>
                  <div class="font-style pl-4 pt-1">
                    <span class="head-style"> Location : </span><span class="value-style">{{ item.location }}</span>
                  </div>
                  <v-divider />
                  <v-card-text class="light-color font-style">
                    Expired Date :<span class="date-style"> {{ item.licence.end | convertToLocal }}</span>
                  </v-card-text>
                </v-col>
                <v-col class="justify-end align-self-end d-flex pb-6 font-style">
                  <v-btn
                    color="red darken-3"
                    class="mt-n2"
                    elevation="1"
                    small
                    @click="removeFromCart(index)"
                  >
                    <v-icon
                      color="FFFFFF"
                      class="font-style"
                    >
                      mdi-close
                    </v-icon> Remove
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item><v-divider />
          </v-card>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="red darken-3"
              font-style="font-style"
              @click="cancelRenew()"
            >
              Clear
            </v-btn>
            <v-btn
              class="mt-2"
              color="#FF3700"
              font-style="font-style"
              @click="showRenewPlans()"
            >
              Renew
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center h-100 ma-5"
        >
          <v-icon class="icon-style light-color">
            mdi-cart-off
          </v-icon>
          <v-card-title class="light-color">
            Cart is Empty
          </v-card-title>
        </div>
        <br>
      </v-card>
    </v-card>
    <centre-spinner :loading="loading" />
    <products-renewal-plans
      v-if="showRenewalPlans"
      @closed="showRenewalPlans = false"
    />
  </v-container>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import ProductsRenewalPlans from './ProductsRenewalPlans.vue';
import moment from 'moment';
export default {
  name: 'ProductRenewalCart',
  components: {
    'centre-spinner': spinner,
    'products-renewal-plans': ProductsRenewalPlans,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  data: () => ({
    loading: false,
    showRenewalPlans: false,
    isCart: false,
  }),
  computed: {
    productRenewalCart () {
      return this.$store.getters['payments/getProductRenewalCart'];
    },
    checkCartExist () {
      if (this.productRenewalCart.length !== 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    removeFromCart (index) {
      this.loading = true;
      this.$store.dispatch('payments/removeProductFromRenewalCart', index).then(() => {
        this.loading = false;
      });
    },
    showRenewPlans () {
      this.showRenewalPlans = true;
    },
    cancelRenew () {
      this.$store.dispatch('payments/removeAllProductFromCart');
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-style {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
}
.link-style {
  color: #37474F;
  font-weight: bold;
}
.light-color {
  color: #FF3700;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.date-style {
  color: #37474F;
}
.card-container {
  width: 100%;
  margin: 30px;
}
.head-style {
  color: #546E7A;
}
.value-style {
  color: #37474F;
}
</style>
