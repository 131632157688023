<template>
  <v-dialog
    v-model="showModal"
    width="1200"
  >
    <v-card class="pa-5">
      <h2 class="title-style pa-4">
        <v-icon class="icon-style">
          mdi-credit-card-check
        </v-icon> Subscription Plans
      </h2>
      <v-card
        class="my-base-vcard-style"
      >
        <br>
        <v-row
          align="center"
          justify="center"
          class="ma-4"
        >
          <div class="main-div">
            <input
              id="toggle"
              v-model="isPricingPlanThreeYear"
              class="input"
              type="checkbox"
              @change="showPricingPlans()"
            >
            <label
              class="label"
              for="toggle"
            >
              <div class="float-left">
                Yearly
              </div>
              <div class="switch">
                <span class="slider round" />
              </div>
              <div class="float-right">
                3-Years
              </div>
            </label>
          </div>
        </v-row>
        <div v-if="isPaymentPlansAvailable">
          <v-card class="my-vcard-style">
            <v-container
              fluid
              class="pa-4 text-center"
            >
              <v-row>
                <v-col
                  v-for="(item, i) in productPaymentPlans"
                  :key="i"
                  class="                   "
                  md="4"
                  lg="4"
                  sm="12"
                >
                  <div v-if="item.active === true && item.prices.length > 0">
                    <template
                      v-for="(price, index) in item.prices"
                    >
                      <div
                        v-if="plansOnPricingPeriod(price.active, price.type)"
                        :key="index"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card
                            :elevation="hover ? 12 : 1"
                            class=""
                            style="border:1px solid #37474F; border-radius:25px;margin-bottom: 20px;"
                            @click="selectPlan(item,price)"
                          >
                            <v-badge
                              :value="showBadge(price.id)"
                              color="green darken-2"
                              overlap
                              bordered
                              icon="mdi mdi-checkbox-marked-circle-outline"
                            >
                              <a>
                                <v-card-title class="text-h6">
                                  <v-row
                                    class="fill-height d-flex justify-center"
                                  >
                                    <v-col cols="12">
                                      <br>
                                      <div>
                                        <p class="plan-style">
                                          {{ item.product_tier.toUpperCase() }}
                                        </p>
                                      </div>
                                      <br>
                                      <div>
                                        <p class="amount-style">
                                          <span class="currency-style">{{ price.currency.toUpperCase() }}</span> {{
                                            price.display_price }}
                                        </p>
                                        <p class="amnt-month-style">
                                          Monthly/Screen
                                        </p>
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      <div>
                                        <p class="amnt-year-style">
                                          Total: {{ price.currency.toUpperCase() }} {{ price.amount | amountConverter }}
                                          /{{ price.pricing_period | periodCoverter }}Year
                                        </p>
                                      </div>
                                      <div>
                                        <v-btn
                                          class="btn-style"
                                          color="#FF3700"
                                          @click.stop="contactUs()"
                                        >
                                          Contact Us
                                        </v-btn>
                                      </div>
                                    </v-col>
                                    <div>
                                      <br>
                                      <p class="description-style">
                                        Media Player Software<br><span class="product-name">BannerEx</span>
                                      </p>
                                    </div>
                                    <br>
                                  </v-row>
                                  <br>
                                </v-card-title>
                              </a>
                            </v-badge>
                          </v-card>
                        </v-hover>
                        <div v-if="price.display_description !== null">
                          <p class="tooltip-style">
                            <v-icon
                              left
                              color="#2E7D32"
                            >
                              mdi-tag
                            </v-icon>{{ price.display_description }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div v-if="item.active === true && item.prices.length === 0">
                    <v-hover
                      v-slot="{ hover }"
                    >
                      <v-card
                        :elevation="hover ? 12 : 1"
                        :class="{ 'on-hover': hover }"
                        style="border:1px solid #37474F; border-radius:25px"
                      >
                        <v-card-title class="text-h6">
                          <v-row
                            class="fill-height flex-column"
                            align="center"
                            justify="center"
                          >
                            <v-col cols="12 ">
                              <br>
                              <div>
                                <p class="plan-style">
                                  {{ item.product_tier.toUpperCase() }}
                                </p>
                              </div>
                              <br>
                              <div>
                                <p class="amount-style">
                                  Custom
                                </p>
                                <p class="amnt-month-style">
                                  as required
                                </p>
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <div>
                                <p class="amnt-year-style">
                                  Total: Quote Based
                                </p>
                              </div>
                              <div>
                                <v-btn
                                  class="btn-style"
                                  color="#FF3700"
                                  @click.stop="contactUs()"
                                >
                                  Contact Us
                                </v-btn>
                              </div>
                            </v-col>
                            <br>
                            <div>
                              <p class="description-style">
                                Media Player Software<br><span class="product-name">BannerEx</span>
                              </p>
                            </div>
                            <br>
                          </v-row>
                        </v-card-title>
                      </v-card>
                    </v-hover>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="mt-2"
                color="red darken-3"
                font-style="font-style"
                @click="showModal = false"
              >
                Close
              </v-btn>
              <v-btn
                class="mt-2"
                color="#FF3700"
                font-style="font-style"
                @click="confirmRenewal()"
              >
                Continue
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </div>
        <br>
        <br>
        <centre-spinner :loading="loading" />
      </v-card>
    </v-card>
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
    <contact-for-purchase
      v-if="ShowContactForm"
      :contact-message="'For more details of this plan, Please  contact us at'"
      @closed="ShowContactForm = false"
    />
    <purchase-prevent-alert
      v-if="showForm"
      :product-type="defaultPlanType"
      @closed="showForm = false"
    />
  </v-dialog>
</template>
<script>
import { stripePublishKey } from 'src/app-globals';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
import ContactForPurchase from '../ContactForPurchase.vue';
import PurchasePreventAlert from '../PurchasePreventAlert.vue';
import Constants from 'src/constants';
export default {
  name: 'ProductsRenewalPlans',
  components: {
    'centre-spinner': spinner,
    'overlay-alert': OverlayAlert,
    'stripe-checkout': StripeCheckout,
    'contact-for-purchase': ContactForPurchase,
    'purchase-prevent-alert': PurchasePreventAlert,
  },
  filters: {
    amountConverter (amount) {
      return amount / 100;
    },
    periodCoverter (period) {
      if (period === 'threeYearly') {
        return 3;
      }
      return 1;
    },
  },
  mixins: [global],
  data () {
    return {
      loading: false,
      publishableKey: stripePublishKey,
      sessionId: '',
      priceId: '',
      isPricingPlanThreeYear: false,
      intervalCount: 1,
      showProgress: false,
      processAlert: '',
      priceType: '',
      showAlert: false,
      message: '',
      productType: '',
      showModal: true,
      selectedContents: [],
      selectedPlan: null,
      selectedPlanPrice: null,
      boardLicenceIdsForRenewal: [],
      paymentMethod: 'card',
      ShowContactForm: false,
      defaultPlanType: '',
      showForm: false,
    };
  },
  computed: {
    productPaymentPlans () {
      const productPaymentPlans = this.$store.getters['payments/getProductPaymentPlans'];
      const index = [0, 2, 1];
      const sortedPlanDetails = index.map(i => productPaymentPlans[i]);
      return sortedPlanDetails;
    },
    subscriptionsHistory () {
        return this.$store.getters['payments/getSubscriptionsHistory'];
      },
    isPaymentPlansAvailable () {
      if (this.productPaymentPlans[0] !== undefined) {
        return true;
      }
      return false;
    },
    productRenewalCart () {
      return this.$store.getters['payments/getProductRenewalCart'];
    },
    checkoutSession () {
      return this.$store.getters['payments/getCheckoutSession'];
    },
    userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    this.loadig = true;
    await this.$store.dispatch('payments/fetchProductPaymentPlans');
    await this.fetchSubscriptionHistory();
    this.loading = false;
  },
  methods: {
    plansOnPricingPeriod (priceStatus, pricingType) {
      if (priceStatus === true && ((pricingType === 'recurring' && this.intervalCount === 1) ||
        (pricingType === 'one_time' && this.intervalCount === 3))) {
        return true;
      }
      return false;
    },
    showPricingPlans () {
      this.selectedContents = [];
      this.loadig = true;
      if (this.isPricingPlanThreeYear === true) {
        this.intervalCount = 3;
        this.loadig = true;
        return;
      }
      this.intervalCount = 1;
      this.loadig = true;
    },
    confirmRenewal () {
      if (this.selectedContents.length === 0) {
        this.$store.dispatch('alert/onAlert', {
            message: 'Please select a plan before proceeding',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
      }
      if (this.subscriptionsHistory[0].subscription.plan !== this.productType) {
        this.defaultPlanType = this.subscriptionsHistory[0].subscription.plan;
        this.showForm = true;
        return;
      }
      this.purchaseConfirm();
    },
    async purchaseConfirm () {
      this.showProgress = true;
      this.processAlert = 'Verifying...';
      await this.$store.dispatch('payments/createCheckoutCustomer', {
        description: `${this.userProfile.first_name} - ${this.userProfile.external_id}`,
        payment_method_type: this.paymentMethod,
      }).then(response => {
        this.createCheckoutSession();
      }).catch(() => {
        this.showProgress = false;
      });
    },
    async createCheckoutSession () {
        const deviceQuantity = this.productRenewalCart.length;
        this.processAlert = 'Confirming...';
        const productDetailsData = [{
          price_id: this.priceId,
          quantity: parseInt(deviceQuantity),
          type: this.productItemType,
        }];
        this.digiBoardLicencesList();
        await this.$store.dispatch('payments/createCheckoutSession', {
          line_items: productDetailsData,
          payment_method_types: this.paymentMethod.split(','),
          mode: this.priceType === 'recurring' ? 'subscription' : 'payment',
          digi_board_licences: this.boardLicenceIdsForRenewal,
        }).then(response => {
          this.sessionId = this.checkoutSession.stripe_id;
          this.redirectToCheckout();
        }).catch(() => {
          this.showProgress = false;
        });
      },
    redirectToCheckout () {
      this.processAlert = 'Redirecting...';
      this.$refs.checkoutRef.redirectToCheckout();
    },
    async fetchPaymentHistory () {
      await this.$store.dispatch('payments/fetchOneTimePaymentHistory');
    },
    async fetchSubscriptionHistory () {
      await this.$store.dispatch('payments/fetchSubscriptionsHistory');
    },
    selectPlan (item, price) {
      this.priceId = price.id;
      this.priceType = price.type;
      this.productType = item.product_tier;
      this.productItemType = item.type;
      this.selectedContents = [];
      this.selectedContents.push(price.id);
    },
    showBadge (item) {
      return this.selectedContents.includes(item);
    },
    contactUs () {
        this.ShowContactForm = true;
      },
    digiBoardLicencesList () {
      this.productRenewalCart.forEach((device) => {
        this.boardLicenceIdsForRenewal.push(device.id);
      });
    },
  },
};
</script>

<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
  margin: 20px;
}
.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}
.plan-style {
  color: #FF3700;
  font-size: 28px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}

.amount-style {
  color: #37474F;
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.amnt-month-style {
  color: #37474F;
  font-size: 13PX;
}
.amnt-year-style {
  color: #37474F;
  font-size: 15PX;
  font-weight: bold;
}

.btn-style {
  margin-left: 15px;
}

.label {
  pointer-events: none;
  display: flex;
  align-items: center;
}
.switch,
.input:checked+.label .left,
.input:not(:checked)+.label .right {
  pointer-events: all;
  cursor: pointer;
}
.input {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #37474F;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked+.label .slider {
  background-color: #FF3700;
}
input:focus+.label .slider {
  box-shadow: 0 0 1px #FF3700;
}
input:checked+.label .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.float-left,
.float-right {
  margin: 0 .5em;
  font-weight: bold;
  font-size: 25px;
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
}
.main-div {
  display: flex;
  justify-content: center;
}
.description-style {
  color: #37474F;
  font-size: 15PX;
  font-family: 'Times New Roman', Times, serif;
}
.product-name {
  color: #546E7A;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.currency-style {
  font-size: 25px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.tooltip-style {
  color: #2E7D32;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
@media (max-width: 768px) {
  .card-style {
    width: 100% !important;
  }
}
</style>
